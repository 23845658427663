import { Middleware, Context } from '@nuxt/types'

const validateUserId: Middleware = (context: Context) => {
  const { error, route } = context
  if (isNaN(Number(route.params.id))) {
    return error({
      statusCode: 404,
      message: ''
    })
  }
}

export default validateUserId
