export default async ({ app }) => {
  if (process.client) {
    // GTMに定義したカスタムイベントを送信することで、SPA画面遷移をトリガーにしてGTMタグの発火が行われる
    app.router.afterEach(() =>
      setTimeout(
        () => {
          if (!Array.isArray(window.dataLayer)) {
            window.dataLayer = []
          }
          window.dataLayer.push({ event: 'spa_page_transition' })
        },
        500 // 画面遷移が完全に完了することを待たないと、前のページのtitleが記録されてしまうのを防ぐ
      )
    )
    app.router.beforeEach((to, from, next) => {
      // Nuxt3に移行済みのページ一覧
      const transferedList = ['index']
      if (transferedList.includes(to.name) && !!from.name && from.name !== to.name) {
        location.href = to.fullPath
      } else next()
    })
  }
}
