const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['blogSearch'] = require('../middleware/blogSearch.js')
middleware['blogSearch'] = middleware['blogSearch'].default || middleware['blogSearch']

middleware['blogSearchRedirect'] = require('../middleware/blogSearchRedirect.ts')
middleware['blogSearchRedirect'] = middleware['blogSearchRedirect'].default || middleware['blogSearchRedirect']

middleware['businessAuthenticated'] = require('../middleware/businessAuthenticated.js')
middleware['businessAuthenticated'] = middleware['businessAuthenticated'].default || middleware['businessAuthenticated']

middleware['domain/profile/getHasOldRecordsRequest/getHasOldRecordsRequest'] = require('../middleware/domain/profile/getHasOldRecordsRequest/getHasOldRecordsRequest.ts')
middleware['domain/profile/getHasOldRecordsRequest/getHasOldRecordsRequest'] = middleware['domain/profile/getHasOldRecordsRequest/getHasOldRecordsRequest'].default || middleware['domain/profile/getHasOldRecordsRequest/getHasOldRecordsRequest']

middleware['endsWithSlash'] = require('../middleware/endsWithSlash.ts')
middleware['endsWithSlash'] = middleware['endsWithSlash'].default || middleware['endsWithSlash']

middleware['isAccessableOutsource'] = require('../middleware/isAccessableOutsource.ts')
middleware['isAccessableOutsource'] = middleware['isAccessableOutsource'].default || middleware['isAccessableOutsource']

middleware['notification'] = require('../middleware/notification.js')
middleware['notification'] = middleware['notification'].default || middleware['notification']

middleware['pages/searchUsers'] = require('../middleware/pages/searchUsers.ts')
middleware['pages/searchUsers'] = middleware['pages/searchUsers'].default || middleware['pages/searchUsers']

middleware['preAuthHash/preAuthHash'] = require('../middleware/preAuthHash/preAuthHash.ts')
middleware['preAuthHash/preAuthHash'] = middleware['preAuthHash/preAuthHash'].default || middleware['preAuthHash/preAuthHash']

middleware['redirectToContinueIfBusinessPreview'] = require('../middleware/redirectToContinueIfBusinessPreview.js')
middleware['redirectToContinueIfBusinessPreview'] = middleware['redirectToContinueIfBusinessPreview'].default || middleware['redirectToContinueIfBusinessPreview']

middleware['redirectToTalkroomsIfNotCoconalaApp'] = require('../middleware/redirectToTalkroomsIfNotCoconalaApp.ts')
middleware['redirectToTalkroomsIfNotCoconalaApp'] = middleware['redirectToTalkroomsIfNotCoconalaApp'].default || middleware['redirectToTalkroomsIfNotCoconalaApp']

middleware['redirectToTopIfMzhcAccount'] = require('../middleware/redirectToTopIfMzhcAccount.ts')
middleware['redirectToTopIfMzhcAccount'] = middleware['redirectToTopIfMzhcAccount'].default || middleware['redirectToTopIfMzhcAccount']

middleware['redirectToTopIfRegistered'] = require('../middleware/redirectToTopIfRegistered.ts')
middleware['redirectToTopIfRegistered'] = middleware['redirectToTopIfRegistered'].default || middleware['redirectToTopIfRegistered']

middleware['requiredUpdateCoconalaAppVersion/forJobMatching'] = require('../middleware/requiredUpdateCoconalaAppVersion/forJobMatching.ts')
middleware['requiredUpdateCoconalaAppVersion/forJobMatching'] = middleware['requiredUpdateCoconalaAppVersion/forJobMatching'].default || middleware['requiredUpdateCoconalaAppVersion/forJobMatching']

middleware['smsAuthenticatedForJobMatching'] = require('../middleware/smsAuthenticatedForJobMatching.ts')
middleware['smsAuthenticatedForJobMatching'] = middleware['smsAuthenticatedForJobMatching'].default || middleware['smsAuthenticatedForJobMatching']

middleware['translate'] = require('../middleware/translate.ts')
middleware['translate'] = middleware['translate'].default || middleware['translate']

middleware['update-view-id'] = require('../middleware/update-view-id.ts')
middleware['update-view-id'] = middleware['update-view-id'].default || middleware['update-view-id']

middleware['validations/validateUserId'] = require('../middleware/validations/validateUserId.ts')
middleware['validations/validateUserId'] = middleware['validations/validateUserId'].default || middleware['validations/validateUserId']

export default middleware
