<template>
  <div
    class="c-theFooter js-the-footer"
    :class="{ 'c-theFooter-mzhc': isMzhcFooter }"
    v-intersect-translate.once
  >
    <footer
      v-if="!isMzhcBeforeLoginFooter"
      class="c-footer"
      ref="footer"
    >
      <div
        class="c-footer_inner"
        v-if="!isSimpleFooter && !isMzhcSimpleFooter"
      >
        <div class="c-footerGuide">
          <div
            class="c-footerGuideList"
            :class="[
              `c-footerGuideList-${$translate.getLanguageCode()}`,
              { 'c-footerGuideList-mzhc': isMzhcFooter }
            ]"
          >
            <div
              v-for="(footerContent, index) in footerContents"
              :key="`footerContent_${index}`"
              class="c-footerGuideList_list"
              :class="{ 'c-footerGuideList_list-mzhc': isMzhcFooter }"
            >
              <div class="isPC">
                <div
                  class="c-footerGuideList_contentHeader"
                  :class="`c-footerGuideList_contentHeader-${footerContent.class}`"
                >
                  {{
                    $te(`footer.${footerContent.text}`)
                      ? $t(`footer.${footerContent.text}`)
                      : footerContent.text
                  }}
                </div>
                <template v-for="(subContent, index) in footerContent.sub">
                  <div
                    v-if="subContent.text"
                    :key="index"
                    class="c-footerGuideList_contentSubHeader"
                  >
                    {{ $t(`footer.${subContent.text}`) }}
                  </div>
                  <GuideSublist
                    :footer-content-sub="subContent.sub"
                    :key="subContent.text"
                    :is-logged-in="isLoggedIn"
                  />
                </template>
              </div>
              <CollapseGuideSublist
                class="isSP"
                :footer-content="footerContent"
                :is-logged-in="isLoggedIn"
              />
            </div>
          </div>
        </div>
        <div class="c-footerInfo">
          <div
            v-if="!isMzhcFooter"
            class="c-footerInfoApp"
          >
            <div>
              <a
                :href="$util.genAppUrl(appStoreUrl, $ua.isFromPc(), true)"
                target="_blank"
              >
                <img
                  src="@/assets/images/common/banner_ios_s.png"
                  alt="Download on the App Store"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </div>
            <div>
              <a
                class="c-footerInfoApp_app c-footerInfoApp_app-android"
                :href="$util.genAppUrl(appStoreUrl, $ua.isFromPc(), false)"
                target="_blank"
              >
                <img
                  src="@/assets/images/common/banner_android_s.png"
                  alt="Android App on Google play"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </div>
          </div>
          <div class="c-footerInfoInquiry">
            <div class="c-footerInfoInquiry_inner">
              <div class="c-footerInfoInquiry_title">カスタマーサポート</div>
              <div class="c-footerInfoInquiry_subtitle">返信が必要な場合はこちら</div>
              <div class="c-footerInfoInquiry_button">
                <DButton
                  :href="$coconala.url('/inquiry')"
                  class="c-inquiryButton"
                  color="primary"
                  type="outlined"
                  size="large"
                  is-bold
                >
                  お問い合わせ
                </DButton>
              </div>
            </div>
          </div>
          <GoikenBox />
          <div class="c-footerInfo_feedback"></div>
        </div>
      </div>
      <div
        class="c-footer_bottom"
        :class="[
          { 'c-footer_bottom-simple': isSimpleFooter },
          { 'c-footer_bottom-mzhcSimple': isMzhcSimpleFooter }
        ]"
      >
        <div
          class="c-footer_bottomInner"
          :class="[
            { 'c-footer_bottomInner-simple': isSimpleFooter },
            { 'c-footer_bottomInner-mzhcSimple': isMzhcSimpleFooter }
          ]"
        >
          <div
            class="c-footer_terms"
            :style="styles"
            v-if="!isSimpleFooter && !isMzhcSimpleFooter"
          >
            <div class="c-footer_snsIcon">
              <a
                class="c-footer_termsLink c-footer_termsLink-sns"
                href="https://twitter.com/coconala"
                target="_blank"
                rel="noopener"
              >
                <CoconalaIcon
                  size="18px"
                  name="x"
                />
              </a>
              <a
                class="c-footer_termsLink c-footer_termsLink-sns"
                href="https://www.facebook.com/coconalajp"
                target="_blank"
                rel="noopener"
              >
                <CoconalaIcon
                  size="20px"
                  name="facebook-brands"
                />
              </a>
              <a
                class="c-footer_termsLink c-footer_termsLink-sns"
                href="https://www.instagram.com/coconala_official"
                target="_blank"
                rel="noopener"
              >
                <CoconalaIcon
                  size="19px"
                  name="instagram"
                />
              </a>
              <a
                class="c-footer_termsLink c-footer_termsLink-youtube"
                href="https://www.youtube.com/channel/UCMUoDRN3g4pZ5KyDuLrr1ag"
                target="_blank"
                rel="noopener"
              >
                <CoconalaIcon
                  size="26px"
                  name="youtube"
                />
              </a>
            </div>
            <div class="c-footer_termsList">
              <a
                class="c-footer_termsLink"
                href="/pages/terms_user"
              >
                利用規約
              </a>
              <a
                class="c-footer_termsLink"
                href="/pages/privacy"
              >
                プライバシーポリシー
              </a>
              <a
                class="c-footer_termsLink"
                href="/pages/cookie_policy"
              >
                外部送信ポリシー
              </a>
              <a
                class="c-footer_termsLink"
                :href="isMzhcFooter ? '/pages/tokushou_mzhc' : '/pages/tokushou'"
              >
                特定商取引法に基づく表記
              </a>
              <a
                class="c-footer_termsLink"
                href="/pages/terms_provider"
              >
                加盟店規約
              </a>
              <a
                class="c-footer_termsLink"
                href="/pages/terms_exchange_point"
              >
                有償ポイントに関する特約
              </a>
              <a
                class="c-footer_termsLink"
                href="/pages/settlement"
              >
                資金決済法に基づく表記
              </a>
            </div>
          </div>
          <div
            v-if="showLanguageSelect"
            :style="styles"
            class="c-languageSelect"
          >
            <DLanguageSelect />
          </div>
          <div class="c-copyright">
            <span v-if="isMzhcSimpleFooter || isMzhcFooter">
              &copy; {{ `2024-${$util.dayjs().get('year')} ${MZHC_COMPANY_INFO.nameEn}` }}
            </span>
            <span v-else>&copy; 2012-{{ $util.dayjs().get('year') }} coconala Inc.</span>
          </div>
        </div>
      </div>
    </footer>
    <MzhcBeforeLoginFooter v-else />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import FooterContents from './data/footer.json'
import MzhcFooterContents from './data/footerForMzhc.json'
import { toTranslatedArrayText } from '~/components/logics/translate/translate'
import { isUserAgentBot } from '~/components/logics/platform'
import { LANGUAGES } from '~/constants/language'
import { getBrowserLanguage } from '~/components/logics/translate/getBrowserLanguage'

// シンプルフッターにするページ（needsPerfectMatch:trueなら完全一致、falseなら前方一致）
const SIMPLE_FOOTER_PATHS = [
  { path: '/customize/requests/add/', needsPerfectMatch: false },
  { path: '/register/top', needsPerfectMatch: true },
  { path: '/orders/post_pay', needsPerfectMatch: true }
]

const MZHC_SIMPLE_FOOTER_PATHS = [
  { path: '/register/identity_in_progress_mzhc', needsPerfectMatch: true },
  { path: '/register/top_mzhc', needsPerfectMatch: true },
  { path: '/pages/consent_form_mzhc', needsPerfectMatch: true }
]

// シンプルフッターの判定には基本SIMPLE_FOOTER_PATHSを使うが、パスにid値が含まれる場合などに対応する場合はこちらを使う
const SIMPLE_FOOTER_PATHS_BY_ROUTER_MATCH = [
  'services-id-reservation', // 予約日時指定画面
  'business-register-invitation_link-linkId'
]

export default {
  name: 'TheFooter',
  components: {
    CollapseGuideSublist: () => import('./components/CollapseGuideSublist'),
    GuideSublist: () => import('./components/GuideSublist'),
    DButton: () => import('~/components/atoms/DS2/DButton'),
    GoikenBox: () => import('~/components/organisms/GoikenBox'),
    DLanguageSelect: () => import('~/components/organisms/DS2/DLanguageSelect'),
    MzhcBeforeLoginFooter: () => import('~/components/organisms/mzhc/MzhcBeforeLoginFooter')
  },
  data() {
    return {
      translatedCategories: [],
      browserLanguage: LANGUAGES.JA.code
    }
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('ui', ['isSP', 'isTB']),
    ...mapState('ui', ['footerBottomTb', 'footerBottomSp']),
    ...mapState('auth', ['appStoreUrl', 'ua']),
    ...mapState('my/header', ['privateAccountInfo']),
    ...mapState('master', ['masterCategories']),
    ...mapState('constants', ['VIRTUAL_CATEGORY', 'MZHC_COMPANY_INFO']),
    paddingBottom() {
      if (this.isSP) {
        return this.footerBottomSp
      }
      if (this.isTB) {
        return this.footerBottomTb
      }
      return ''
    },
    styles() {
      return {
        'padding-bottom': this.paddingBottom
      }
    },
    masterFooterContents() {
      return this.isMzhcFooter ? MzhcFooterContents : FooterContents
    },
    footerContents() {
      const categories = this.isTranslatableTarget
        ? this.translatedCategories
        : this.masterCategories
      const categoryList = categories.flatMap(masterCategory => {
        return this.isMzhcFooter && !masterCategory.businessFlag
          ? []
          : [
              {
                id: masterCategory.id,
                text: masterCategory.name,
                url: `/categories/${masterCategory.id}`,
                url_type: 'coconala'
              }
            ]
      })
      if (!this.isMzhcFooter) {
        // カテゴリ一覧はマスタに情報がないので手動で作成
        categoryList.push({
          text: 'sitemap',
          url: '/categories/',
          url_type: 'coconala'
        })
      }

      return this.masterFooterContents.map(content => {
        if (content.text === 'categories')
          content.sub[0].sub = this.extendChildCategory(categoryList)
        return content
      })
    },
    isSimpleFooter() {
      return (
        SIMPLE_FOOTER_PATHS.some(pathObject => {
          return pathObject.needsPerfectMatch
            ? window.location.pathname === pathObject.path
            : window.location.pathname.startsWith(pathObject.path)
        }) || SIMPLE_FOOTER_PATHS_BY_ROUTER_MATCH.includes(this.$route.name)
      )
    },
    isMzhcSimpleFooter() {
      return MZHC_SIMPLE_FOOTER_PATHS.some(pathObject => {
        return pathObject.needsPerfectMatch
          ? window.location.pathname === pathObject.path
          : window.location.pathname.startsWith(pathObject.path)
      })
    },
    isMzhcBeforeLoginFooter() {
      return !this.isLoggedIn && !!this.$route.query.is_mzhc
    },
    isMzhcFooter() {
      return this.privateAccountInfo.isMzhc
    },
    showLanguageSelect() {
      if (this.$translate.isTranslatableTarget()) return true
      return (
        this.$translate.isCurrentTranslatePage() &&
        this.browserLanguage !== LANGUAGES.JA.code &&
        !this.isSimpleFooter &&
        !this.isMzhcFooter &&
        !this.isMzhcSimpleFooter &&
        !isUserAgentBot(this.ua)
      )
    },
    isTranslatableTarget() {
      return this.$translate.isTranslatableTarget()
    }
  },
  async created() {
    if (!this.isTranslatableTarget || process.server) return
    const translatedCategoryNames = await toTranslatedArrayText(
      this.masterCategories.map(category => category.name)
    )
    this.translatedCategories = this.$util.clone(this.masterCategories).map(category => {
      if (translatedCategoryNames[category.name]) {
        category.name = translatedCategoryNames[category.name]
      }
      return category
    })
  },
  mounted() {
    this.browserLanguage = getBrowserLanguage()
  },
  methods: {
    extendChildCategory(categoryList) {
      const divinationPhone = this.VIRTUAL_CATEGORY.DIVINATION_PHONE
      // 拡張したいカテゴリを定義
      const exChildCategories = [
        {
          // 途中挿入したい親カテゴリID
          parentCategoryId: divinationPhone.CONDITION.CATEGORY_ID,
          data: {
            // IDはなんでもいい
            id: `${divinationPhone.CONDITION.CATEGORY_ID}-${divinationPhone.CONDITION.SERVICE_CLASS}`,
            text: divinationPhone.NAME,
            url: `/categories/${divinationPhone.CONDITION.CATEGORY_ID}?service_kind=${divinationPhone.CONDITION.SERVICE_CLASS}`,
            url_type: 'coconala'
          }
        }
      ]

      const returnCategory = []
      categoryList.forEach(c => {
        returnCategory.push(c)
        const childCategories = exChildCategories.filter(e => c.id === e.parentCategoryId)
        if (childCategories.length > 0) {
          returnCategory.push(...childCategories.map(childCategory => childCategory.data))
        }
      })
      return returnCategory
    }
  }
}
</script>
<style lang="scss" scoped>
.isPC {
  display: block;
}
.isSP {
  display: none;
}

// 高さが変わる場合は、assets/stylesheets/lib/_variables.scssの変数も修正が必要
.c-theFooter {
  z-index: z(footer);
  margin-top: 60px;
  color: $color-white;
  body.-withoutFooterMargin & {
    margin-top: 0;
  }

  // ファイル外からクラスを付与している
  &-withoutMarginTop {
    margin-top: 0;
  }
  &-withoutMarginTopOnBlogSearchSP {
    @media (max-width: breakpoint(SearchBlogs, M)) {
      margin-top: 0;
    }
  }
  &-withoutMarginTopOnMypageBlog {
    @media (max-width: breakpoint(Mypage, L)) {
      margin-top: 0;
    }
  }
  &-mzhc {
    .c-footer {
      background: $ds2-color-business-navy-1000;
      &_bottom {
        background-color: $ds2-color-business-navy-1000;
        .c-copyright {
          background-color: $ds2-color-business-navy-1000;
        }
      }
    }
  }
}

.c-footer {
  width: 100%;
  height: auto;
  background: $footer-bg-color;

  &_inner {
    position: relative;
    display: flex;
    margin: auto;
    padding: 56px 0 26px;
    width: 1240px;
    height: auto;
    font-size: 12px;
    line-height: 1.5;

    justify-content: space-between;
  }

  &_bottom {
    background-color: $footer-bg-color-deep;
    &-mzhcSimple {
      background-color: $ds2-color-business-navy-1000;
    }
  }
  &_bottomInner {
    display: flex;
    margin: 0 auto;
    padding: 0 12px;
    max-width: 1240px;
    height: 52px;

    align-items: center;
    justify-content: space-between;

    &-simple,
    &-mzhcSimple {
      padding: 16px 0;
      height: auto;

      align-items: center;
      justify-content: center;
      .c-copyright {
        padding: 0;

        align-items: center;
        justify-content: center;
      }
    }
    &-mzhcSimple {
      .c-copyright {
        background-color: $ds2-color-business-navy-1000;
      }
    }
  }
  &_terms {
    display: flex;
    flex: 1;

    align-items: center;
  }
  &_snsIcon {
    display: flex;
    font-size: 20px;

    align-items: baseline;
  }
  &_termsList {
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &_termsLink {
    @include link-effect;

    margin-right: 1em;
    color: $ds2-color-gray-50;
    font-size: 12px;
    &-sns {
      margin-right: 16px;
    }
    &-youtube {
      width: 26px;
      height: 20px;
    }
  }
}

.c-footerCollapse {
  // 依存関係ありのためBlockをネスト
  .c-footerGuideList {
    &_contentHeader {
      color: $color-white;
    }
  }
}

.c-footerGuideList {
  display: grid;

  gap: 16px;
  grid-template-areas:
    'services buyer provider categories beginners'
    'services buyer provider categories about';
  grid-template-columns: repeat(5, 178px);
  grid-template-rows: auto 1fr;

  &-mzhc {
    grid-template-areas: 'services buyer categories beginners';
  }
  &_contentHeader {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 14px;
  }
  &_contentSubHeader {
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: bold;
  }
  &_list {
    &:nth-of-type(1) {
      grid-area: services;
    }
    &:nth-of-type(2) {
      grid-area: buyer;
    }
    &:nth-of-type(3) {
      grid-area: provider;
    }
    &:nth-of-type(4) {
      grid-area: categories;
    }
    &:nth-of-type(5) {
      grid-area: beginners;
    }
    &:nth-of-type(6) {
      grid-area: about;
    }

    &-mzhc {
      &:nth-of-type(1) {
        grid-area: services;
      }
      &:nth-of-type(2) {
        grid-area: buyer;
      }
      &:nth-of-type(3) {
        grid-area: categories;
      }
      &:nth-of-type(4) {
        grid-area: beginners;
      }
    }
  }
}

::v-deep .c-footerGuideSublist {
  &_list {
    margin-bottom: 8px;
    a {
      @include link-effect;

      display: inline-block;
      color: $ds2-color-white;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

::v-deep .c-footerVision {
  font-size: 12px;

  &_caption {
    margin-bottom: 4px;
    font-weight: bold;
  }
}

.c-footerInfo {
  flex-basis: 286px;
}

.c-footerInfoApp {
  display: grid;
  padding: 0 0 16px;

  gap: 14px;
  grid-template-columns: 136px 136px;

  &_app {
    &-android {
      display: block;
      margin-top: -10px;
      margin-left: -12px;
      width: 158px;
      height: 0;
    }
  }
}

.c-footerInfoInquiry {
  border-radius: 4px;
  background: $ds2-color-gray-25;
  font-size: 13px;

  &_inner {
    padding: 12px 0;
    color: $ds2-color-gray-700;
    text-align: center;
  }

  &_title {
    letter-spacing: 0.05em;
    font-size: 15px;
  }

  &_subtitle {
    margin-bottom: 8px;
    letter-spacing: 0.05em;
    font-size: 12px;
  }
}

.c-inquiryButton {
  width: 200px;
  background-color: $ds2-color-white;
}
.c-languageSelect {
  margin-right: 32px;

  ::v-deep .d-selectCustomizeTrigger {
    position: initial !important;
  }
}

.c-copyright {
  text-align: center;
  font-size: 11px;
  line-height: 1;
}

@media (min-width: breakpoint(Footer, S)) and (max-width: breakpoint(Footer, L) - 1) {
  .c-footer {
    &_inner {
      flex-direction: column;
      width: 100%;
    }
    &_termsList {
      justify-content: center;
    }
    &_bottom {
      background: transparent;
      &-simple {
        padding: 16px 0;
        background-color: $footer-bg-color-deep;
      }
      &-mzhcSimple {
        padding: 16px 0;
        background-color: $ds2-color-business-navy-1000;
      }
    }
    &_bottomInner {
      display: block;
      margin: 0 auto;
      padding: 0;
      height: auto;
      &-simple,
      &-mzhcSimple {
        height: auto;

        align-items: center;
        justify-content: center;
        .c-copyright {
          align-items: center;
          justify-content: center;
        }
      }
    }
    &_terms {
      margin: 0 auto;
      margin-bottom: 32px;
      max-width: 426px;

      flex-wrap: wrap;
      justify-content: flex-start;
    }
    &_snsIcon {
      margin: 0 auto;
      padding-top: 12px;
    }
    &_termsLink {
      display: inline-block;
      margin-bottom: 12px;
      color: $ds2-color-gray-100;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  .c-languageSelect {
    display: flex;
    margin: 0 auto 44px;

    justify-content: center;
  }
  .c-footerGuide {
    margin: 0 auto 48px;
    border-right: 0;

    flex-basis: 100%;
  }
  .c-footerGuideList {
    grid-template-areas:
      'services buyer categories beginners'
      'services provider categories about';
    grid-template-columns: repeat(4, 178px);
    &_list {
      &:last-of-type {
        margin-top: -52px;
      }

      &-mzhc {
        &:last-of-type {
          margin-top: 0;
        }
      }
    }
    &-zh-CN,
    &-zh-TW {
      .c-footerGuideList_list {
        &:last-of-type {
          margin-top: 0;
        }
      }
    }
    &-ko {
      .c-footerGuideList_list {
        &:last-of-type {
          margin-top: -18px;
        }
      }
    }
  }
  .c-footerInfo {
    flex-basis: 100%;
  }
  .c-footerInfoApp {
    margin: 0 auto;
    padding: 0 0 26px;
    max-width: 286px;
    width: calc(100% - 48px);
  }
  .c-footerInfoInquiry {
    margin: 0 auto;
    max-width: 286px;
    width: calc(100% - 48px);
  }
  .c-copyright {
    padding: 4px 0;
    width: auto;
    background-color: $footer-bg-color-deep;

    justify-content: center;
  }
}

@media (max-width: breakpoint(Footer, S) - 1) {
  .isPC {
    display: none;
  }
  .isSP {
    display: block;
  }
  .c-theFooter {
    margin-top: 0;
  }

  .c-footer {
    &_inner {
      flex-direction: column;
      padding: 0;
      width: 100%;
    }
    &_terms {
      margin: 0 auto;
      padding: 48px 0 32px;
      max-width: 437px;

      flex-wrap: wrap;
      justify-content: center;
    }
    &_bottom {
      background-color: transparent;
      &-simple {
        padding: 16px 0;
        background-color: $footer-bg-color-deep;
      }
      &-mzhcSimple {
        padding: 16px 0;
        background-color: $ds2-color-business-navy-1000;
      }
    }
    &_bottomInner {
      display: block;
      margin: 0 auto;
      padding: 0;
      height: auto;
      &-simple,
      &-mzhcSimple {
        padding: 0;
      }
    }
    &_snsIcon {
      margin-right: 0;
      line-height: 1;

      justify-content: center;
    }
    &_termsList {
      justify-content: center;
    }
    &_termsLink {
      margin: 0 6px 12px;
      font-size: 11px;
      line-height: 1.63;
      &-sns,
      &-youtube {
        margin: 0 12px 24px;
      }
    }
  }

  .c-languageSelect {
    display: flex;
    margin: 0 auto 44px;

    justify-content: center;
  }

  .c-footerGuide {
    margin-right: 0;
    padding: 0 0 48px;
    width: 100%;
    border: 0;
    border-bottom: 0;

    flex-basis: 0;
  }

  .c-footerInfo {
    margin-right: 0;

    flex-basis: 100%;
  }

  .c-footerInfoApp {
    margin: 0 auto;
    padding: 0 0 24px;
    width: 327px;

    gap: 31px;
    grid-template-columns: 148px 148px;

    &_app {
      &-android {
        margin-top: -11px;
        margin-right: 0;
        margin-left: -20px;
        width: 172px;
      }
    }
  }

  .c-footerInfoInquiry {
    margin: 0 auto;
    width: 327px;
  }

  .c-footerGuideList {
    gap: 0;
    grid-template-areas:
      'services'
      'buyer'
      'provider'
      'categories'
      'beginners'
      'about';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .c-copyright {
    flex-direction: column-reverse;
    background-color: $footer-bg-color-deep;
    line-height: 1.5;

    align-items: center;
  }
  .c-copyrightLogo {
    margin: 0 auto;
    margin-left: 0;
  }
}
</style>
