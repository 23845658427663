import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import {
  AddBookmarkRequest,
  AttachmentFiles,
  CopyRequest,
  DeleteBookmarkRequest,
  DeleteRequestInformationRequest,
  GetMyRequestsRequest,
  GetMyRequestsResponse,
  GetPopularRequestsRequest,
  GetPopularRequestsResponse,
  GetRecommendRequestsRequest,
  GetRecommendRequestsResponse,
  GetRequestCopyRequest,
  GetRequestCopyResponse,
  IsAuthorizedRequest,
  IsAuthorizedResponse,
  ListBookmarksRequest,
  ListBookmarksResponse,
  ListCopyTargetRequestsRequest,
  ListProposedRequestsRequest,
  ListProposedRequestsResponse,
  RequestItem,
  VerifyNoOpenedRequestRequest
} from '~/stub/apigateway/request/request_pb'
import { RequestService } from '~/stub/apigateway/request/request_pb_service'
import { Stripped, stripValue, toInt32Value, toStringValue, toBoolValue } from '~/grpc/grpc-util'

type GetMyRequestsRequestType = Stripped<GetMyRequestsRequest.AsObject>
type GetMyRequestsResponseType = Stripped<GetMyRequestsResponse.AsObject>
type VerifyNoOpenedRequestRequestType = Stripped<VerifyNoOpenedRequestRequest.AsObject>
const initialValueMyRequests = stripValue(new GetMyRequestsResponse().toObject())

export type CopyRequestType = CopyRequest.AsObject
export type CopyRequestDetailType = GetRequestCopyResponse.AsObject

export type RequestItemType = RequestItem.AsObject

export type AttachmentFilesType = AttachmentFiles.AsObject

export type GetRecommendRequestsRequestType = Stripped<GetRecommendRequestsRequest.AsObject>
export type GetRecommendRequestsResponseType = Stripped<GetRecommendRequestsResponse.AsObject>
export type GetPopularRequestsResponseType = Stripped<GetPopularRequestsResponse.AsObject>

export const initialValueOfRecommendRequests: GetRecommendRequestsResponseType = {
  algorithm: undefined,
  impressionParamsJson: undefined,
  recommendRequestsList: [],
  currentDate: undefined
}

export const initialValueOfPopularRequests: GetPopularRequestsResponseType = {
  requestsList: []
}

const BOOKMARK_PER_PAGE: number = 20
const PROPOSAL_PER_PAGE: number = 20

export type ListBookmarksResponseType = Stripped<ListBookmarksResponse.AsObject>
export const initBookmarkList = stripValue(
  new ListBookmarksResponse().toObject()
) as ListBookmarksResponseType

export type ListProposeResponseType = Stripped<ListProposedRequestsResponse.AsObject>
export const initProposedlist = stripValue(
  new ListProposedRequestsResponse().toObject()
) as ListProposeResponseType

class RequestAccessor extends GrpcAccessorBase {
  isAuthorizedRequest(): Promise<Stripped<IsAuthorizedResponse.AsObject>> {
    const metadata = this.getMetadata()
    const request = new IsAuthorizedRequest()
    return grpcClient({
      method: RequestService.IsAuthorized,
      request,
      metadata
    })
  }

  getListCopyTargetRequests() {
    const metadata = this.getMetadata()
    const request = new ListCopyTargetRequestsRequest()
    return grpcClient({
      method: RequestService.ListCopyTargetRequests,
      request,
      metadata
    })
  }

  getRequestCopy(params: GetRequestCopyRequest.AsObject) {
    const metadata = this.getMetadata()
    const request = new GetRequestCopyRequest()
    request.setRequestId(params.requestId)
    request.setIsDraft(params.isDraft)
    return grpcClient({
      method: RequestService.GetRequestCopy,
      request,
      metadata
    })
  }

  deleteRequestInformation(requestId: number) {
    const metadata = this.getMetadata()
    const request = new DeleteRequestInformationRequest()
    request.setId(requestId)
    return grpcClient({
      method: RequestService.DeleteRequestInformation,
      request,
      metadata
    })
  }

  addBookmark(requestId: number) {
    const metadata = this.getMetadata()
    const request = new AddBookmarkRequest()

    request.setRequestId(requestId)

    return grpcClient({
      method: RequestService.AddBookmark,
      request,
      metadata
    })
  }

  deleteBookmark(requestId: number) {
    const metadata = this.getMetadata()
    const request = new DeleteBookmarkRequest()

    request.setRequestId(requestId)

    return grpcClient({
      method: RequestService.DeleteBookmark,
      request,
      metadata
    })
  }

  fetchAllBookmarks(page: number, perPage: number = BOOKMARK_PER_PAGE) {
    const metadata = this.getMetadata()
    const request = new ListBookmarksRequest()

    request.setPage(page)
    request.setPerPage(perPage)

    return grpcClient({
      method: RequestService.ListBookmarks,
      request,
      metadata,
      strip: true
    })
  }

  fetchAllProposals(page: number) {
    const metadata = this.getMetadata()
    const request = new ListProposedRequestsRequest()

    request.setPage(page)
    request.setPerPage(PROPOSAL_PER_PAGE)

    return grpcClient({
      method: RequestService.ListProposedRequests,
      request,
      metadata,
      strip: true
    })
  }

  getMyRequests(param: GetMyRequestsRequestType): Promise<GetMyRequestsResponseType> {
    const metadata = this.getMetadata()
    const request = new GetMyRequestsRequest()
    request.setPage(toInt32Value(param.page))
    request.setPerPage(toInt32Value(param.perPage))

    return grpcClient({
      method: RequestService.GetMyRequests,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * おすすめ募集のデータを取得する
   */
  getRecommendRequests(
    params: GetRecommendRequestsRequestType
  ): Promise<GetRecommendRequestsResponseType> {
    const metadata = this.getMetadata()
    const request = new GetRecommendRequestsRequest()
    request.setAlgorithm(toStringValue(params.algorithm))

    return grpcClient({
      method: RequestService.GetRecommendRequests,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 人気の募集のデータを返す
   */
  getPopularRequests(): Promise<GetPopularRequestsResponseType> {
    const metadata = this.getMetadata()
    const request = new GetPopularRequestsRequest()

    return grpcClient({
      method: RequestService.GetPopularRequests,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * ユーザー間にオープン中の相談がないことをチェックする
   */
  verifyNoOpenedRequests(param: VerifyNoOpenedRequestRequestType) {
    const metadata = this.getMetadata()
    const request = new VerifyNoOpenedRequestRequest()

    request.setTargetUserId(toInt32Value(param.targetUserId))
    request.setIsProvider(toBoolValue(param.isProvider))

    return grpcClient({
      method: RequestService.VerifyNoOpenedRequest,
      request,
      metadata,
      strip: true
    })
  }
}

export default RequestAccessor

export type { GetMyRequestsRequestType, GetMyRequestsResponseType }
export { initialValueMyRequests }
