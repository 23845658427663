import { has } from 'lodash-es'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import objectSupport from 'dayjs/plugin/objectSupport'
dayjs.locale('ja')
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(objectSupport)
dayjs.extend(timezone)
dayjs.extend(utc)
import { APP_STORE_URL } from '~/constants/urls'

/** 日本標準時判定フラグ */
const isJst = new Date().getTimezoneOffset() === -540
if (!isJst) dayjs.tz.setDefault('Asia/Tokyo')

/** 半角文字から全角文字への変換用マップ */
// prettier-ignore
const HALF_WIDTH_MAP = {
  // 半角カナ
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  '｡': '。',
  '､': '、',
  ｰ: 'ー',
  '｢': '「',
  '｣': '」',
  '･': '・',
  ﾞ: '゛',
  ﾟ: '゜',
  // 半角記号
  '"': '”',
  '\'': '’',
  '`': '‘',
  '\\': '￥',
  '~': '〜'
}

export default {
  // https://github.com/iamkun/dayjs
  dayjs,
  /**
   * デフォルトタイムゾーンのdayjsインスタンス取得
   * @param {import('dayjs').ConfigType | null} time
   */
  getDefaultTzDayjs(time = null) {
    // タイムゾーンが日本標準時でなければデフォルトタイムゾーンを利用する
    if (time) {
      return isJst ? dayjs(time) : dayjs.tz(time)
    }
    return isJst ? dayjs() : dayjs.tz()
  },

  /**
   * unixtimeがperiodDate日以上前かどうか
   * @param number unixtime
   * @param number periodDate
   * @return boolean
   */
  isBeforePeriodDate(unixtime, periodDate) {
    return unixtime <= dayjs().subtract(periodDate, 'day').unix()
  },

  /**
   * 今日がstartDateとendDateの期間中かどうか
   * @param startDate
   * @param endDate
   * @returns {boolean}
   */
  isDuring(startDate, endDate) {
    const today = this.getDefaultTzDayjs().startOf('d')
    return (
      this.getDefaultTzDayjs(startDate).startOf('d') <= today &&
      today <= this.getDefaultTzDayjs(endDate).startOf('d')
    )
  },

  // copy to clipboard
  copy(string) {
    let temp = document.createElement('div')

    temp.appendChild(document.createElement('pre')).textContent = string

    let s = temp.style
    s.position = 'fixed'
    s.left = '-100%'

    document.body.appendChild(temp)
    document.getSelection().selectAllChildren(temp)

    let result = document.execCommand('copy')

    document.body.removeChild(temp)
    return result
  },

  /**
   * 数値に三桁毎カンマつける
   * @param {any} num
   * @returns {string}
   */
  numberWithDelimiter(num) {
    const numberValue = Number(num)
    if (isNaN(numberValue)) return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  // 指定属性のスタイル値取得
  getStyle(element, property, numericalize = true) {
    let style = element.currentStyle || document.defaultView.getComputedStyle(element, '')
    let value = style[property]
    if (numericalize && value.match(/px$/)) {
      value = Number(value.replace('px', ''))
    }
    return value
  },

  // deep copy
  clone(object) {
    if (!object) return null
    return JSON.parse(JSON.stringify(object))
  },

  // ドット区切りの文字列からobjectの中身を掘り下げる
  // resolveObject('hoge.fuga', obj) == obj['hoge']['fuga']
  resolveObject(path, obj) {
    return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self)
  },

  // native javascriptのaddEventListenerのラッパー
  // スペース区切りで複数イベント指定を許容
  on(eventString, el, callback, useCapture = false) {
    let events = eventString.split(' ').filter(e => e)
    events.forEach(event => {
      el.addEventListener(event, callback, useCapture)
    })
  },

  // 配列の中身を足し上げる
  sum(array) {
    if (!array || !array.length) return 0
    return array.reduce((x, y) => x + y)
  },

  // from..toまで数値で埋める配列を作成
  range(from, to) {
    let array = []
    for (let i = from; i <= to; i++) {
      array.push(i)
    }
    return array
  },

  // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/round#A_better_solution
  round(number, precision) {
    const shift = (number, precision, reverseShift) => {
      if (reverseShift) {
        precision = -precision
      }
      let numArray = ('' + number).split('e')
      return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision))
    }
    return shift(Math.round(shift(number, precision, false)), precision, true)
  },

  truncate(str, num) {
    let truncated = str.substring(0, num)
    return str !== truncated ? `${truncated}...` : truncated
  },

  resizeImage(base64image, minSize, mimeType = 'image/jpeg') {
    return new Promise(resolve => {
      const MIN_SIZE = minSize
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const newImage = new Image()
      const self = this
      newImage.crossOrigin = 'Anonymous'
      newImage.onload = function () {
        let dstWidth, dstHeight
        if (this.width > this.height) {
          dstWidth = MIN_SIZE
          dstHeight = self.round((this.height * MIN_SIZE) / this.width, -1)
        } else {
          dstHeight = MIN_SIZE
          dstWidth = self.round((this.width * MIN_SIZE) / this.height, -1)
        }
        canvas.width = dstWidth
        canvas.height = dstHeight
        ctx.drawImage(this, 0, 0, this.width, this.height, 0, 0, dstWidth, dstHeight)
        resolve(canvas.toDataURL(mimeType))
      }
      newImage.src = base64image
    })
  },

  prettyDate(unixtime) {
    const from = new Date(unixtime * 1000)
    const diff = new Date().getTime() - from.getTime()
    const elapsed = new Date(diff)

    if (diff < 0) {
      return '少し前'
    }

    const elapsedYear = elapsed.getUTCFullYear() - 1970
    if (elapsedYear >= 3) {
      return '3年以上前'
    }
    if (elapsedYear > 0) {
      return elapsedYear + '年前'
    }

    const elapsedMonth = elapsed.getUTCMonth()
    if (elapsedMonth > 0) {
      return elapsedMonth + 'ヶ月前'
    }

    const elapsedDate = elapsed.getUTCDate() - 1
    if (elapsedDate > 0) {
      return elapsedDate + '日前'
    }

    const elapsedHours = elapsed.getUTCHours()
    if (elapsedHours > 0) {
      return elapsedHours + '時間前'
    }

    const elapsedMinutes = elapsed.getUTCMinutes()
    if (elapsedMinutes > 0) {
      return elapsedMinutes + '分前'
    }

    return '少し前'
  },

  prettyDateForActivity(unixtime) {
    const from = new Date(unixtime * 1000)
    const diff = new Date().getTime() - from.getTime()
    const elapsed = new Date(diff)

    if (diff < 0) {
      return '0分前'
    }

    const elapsedYear = elapsed.getFullYear() - 1970
    if (elapsedYear > 0) {
      const year = from.getFullYear()
      const month = from.getMonth() + 1
      const date = from.getDate()
      return `${year}年${month}月${date}日`
    }

    const elapsedDate = Math.floor(diff / (1000 * 60 * 60 * 24))
    if (elapsedDate >= 7) {
      const month = from.getMonth() + 1
      const date = from.getDate()
      return `${month}月${date}日`
    }

    if (elapsedDate > 0) {
      return elapsedDate + '日前'
    }

    const elapsedHours = Math.floor(diff / (1000 * 60 * 60))

    if (elapsedHours > 0) {
      return elapsedHours + '時間前'
    }

    const elapsedMinutes = elapsed.getMinutes()
    if (elapsedMinutes > 0) {
      return elapsedMinutes + '分前'
    }

    return '0分前'
  },

  /**
   * 日付の表記のフォーマット
   * 年度をまたいだ場合に年はYYYY年を表示する
   * @param unixtime
   * @returns {string}
   */
  prettyDateForActivityV2(unixtime) {
    const from = new Date(unixtime * 1000)
    const diff = new Date().getTime() - from.getTime()
    const elapsed = new Date(diff)

    if (diff < 0) {
      return '0分前'
    }

    const fromYear = from.getFullYear()
    const year = new Date().getFullYear()
    if (fromYear !== year) {
      const year = from.getFullYear()
      const month = from.getMonth() + 1
      const date = from.getDate()
      return `${year}年${month}月${date}日`
    }

    const elapsedDate = Math.floor(diff / (1000 * 60 * 60 * 24))
    if (elapsedDate >= 7) {
      const month = from.getMonth() + 1
      const date = from.getDate()
      return `${month}月${date}日`
    }

    if (elapsedDate > 0) {
      return elapsedDate + '日前'
    }

    const elapsedHours = Math.floor(diff / (1000 * 60 * 60))
    if (elapsedHours > 0) {
      return elapsedHours + '時間前'
    }

    const elapsedMinutes = elapsed.getMinutes()
    if (elapsedMinutes > 0) {
      return elapsedMinutes + '分前'
    }

    return '0分前'
  },

  // 数字の桁を漢字表記にする（現在万のみ対応）&カンマ区切りにする
  //       例： 10000000 => 1,000万
  //   案件マッチング用  数字が1桁万円以上のとき、下4桁を削除する
  //   例： 10000000 => 1,000、10000 => 10,000
  prettyNumber(number, addTenThousandUnit = true) {
    if (number >= 10000) {
      const stringNumber = String(number)
      const slicedNumber = stringNumber.slice(0, stringNumber.length - 4)
      if (addTenThousandUnit) {
        return this.numberWithDelimiter(slicedNumber) + '万'
      }
      return this.numberWithDelimiter(slicedNumber)
    }

    return this.numberWithDelimiter(number)
  },

  // 数字が二桁万円以上のとき、桁を漢字表記にする（現在万のみ対応） & カンマ区切りにする
  //   公開募集・見積り用
  //   例： 10000000 => 1,000万、10000 => 10,000
  // 多言語対応する場合は $translate.prettyNumberForRequestをご利用ください
  prettyNumberForRequest(number) {
    if (number >= 100000) {
      const stringNumber = String(number)
      const slicedNumber = stringNumber.slice(0, stringNumber.length - 4)
      return this.numberWithDelimiter(slicedNumber) + '万'
    }

    return this.numberWithDelimiter(number)
  },

  // 数字が千円以上のとき、桁を漢字表記にする（現在千、万のみ対応） & カンマ区切りにする
  //   例： 10000000 => 1,000万、10000 => 1万、5000 => 5千、1000 => 千
  prettyNumberToKanji(number) {
    if (number >= 1000 && number < 10000) {
      const firstNumber = String(number).slice(0, 1).replace('1', '')
      return `${firstNumber}千`
    }

    return this.prettyNumber(number)
  },

  getGrpcErrorMessage(grpcTrailers, isProcessClient) {
    const bin = grpcTrailers.headersMap['grpc-status-details-bin']
    if (!bin || typeof bin !== 'object' || !bin[0]) {
      return null
    }

    let escaped = ''
    if (isProcessClient) {
      escaped = escape(atob(bin[0]))
    } else {
      // SSR(Node)環境ではatob()が使えないため
      const bufferString = Buffer.from(bin[0], 'base64').toString('binary')
      escaped = escape(bufferString)
    }
    const encoded = escaped.split('ja-JP')[1]
    const decoded = decodeURIComponent(encoded)
    // '\u0012\u0015'のような文字種が固定されていない謎の2文字が入ってくるためsubstring()する
    return decoded.substring(2)
  },
  getNotifyErrorTitle(grpcCode, grpcErrorMessage) {
    return (
      grpcErrorMessage ||
      (grpcCode === 16 ? 'ログイン期限が切れました' : 'システムエラーが発生しました')
    )
  },
  getNotifyErrorText(grpcCode, grpcErrorMessage) {
    return grpcErrorMessage
      ? ''
      : grpcCode === 16
      ? 'ログイン画面より再度ログインしてください'
      : 'しばらく時間をおいてやり直してください'
  },
  // grpcStatusCodeを受け取りhttpStatusCodeを返す
  grpcCodeToHttpStatusCode(grpcCode) {
    // OK = 0 -> 200
    // CANCELLED = 1 -> 499
    // UNKNOWN = 2 -> 500
    // INVALID_ARGUMENT = 3 -> 400
    // DEADLINE_EXCEEDED = 4 -> 504
    // NOT_FOUND = 5 -> 404
    // ALREADY_EXISTS = 6 -> 409
    // PERMISSION_DENIED = 7 -> 403
    // RESOURCE_EXHAUSTED = 8 -> 429
    // FAILED_PRECONDITION = 9 -> 400
    // ABORTED = 10 -> 409
    // OUT_OF_RANGE = 11 -> 400
    // UNIMPLEMENTED = 12 -> 501
    // INTERNAL = 13 -> 500
    // UNAVAILABLE = 14 -> 503
    // DATA_LOSS = 15 -> 500
    // UNAUTHENTICATED = 16 -> 401
    const code = [
      200, 499, 500, 400, 504, 404, 409, 403, 429, 400, 409, 400, 501, 500, 503, 500, 401
    ]
    return code[grpcCode]
  },
  toCamelCase(str) {
    if (str.indexOf('_') === -1) {
      return str
    }
    return str
      .split('_')
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase()
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      })
      .join('')
  },
  // スネークケースからキャメルケースに変換（オブジェクト）.
  toCamelCaseObject(obj) {
    const result = {}
    Object.keys(obj).forEach(key => {
      result[this.toCamelCase(key)] = obj[key]
    })
    return result
  },
  // キャメルケースからスネークケースに変換（文字列）.
  toSnakeCaseObject(obj) {
    function toUnderscoreCase(str) {
      if (str.indexOf('_') !== -1) {
        return str
      }
      return str
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
    }
    const result = {}
    Object.keys(obj).forEach(key => {
      result[toUnderscoreCase(key)] = obj[key]
    })
    return result
  },
  hasPath(object, path) {
    return has(object, path)
  },
  // Chrome Platform AnalyticsのUUID生成処理を参考にした、UUID V4規格のUUIDを生成する
  // 参考 https://qiita.com/psn/items/d7ac5bdb5b5633bae165
  generateUuid() {
    // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
    // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')

    for (let i = 0, len = chars.length; i < len; i++) {
      switch (chars[i]) {
        case 'x':
          chars[i] = Math.floor(Math.random() * 16).toString(16)
          break
        case 'y':
          chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16)
          break
      }
    }
    return chars.join('')
  },
  getYoutubeThumbnailURL(id, size = 'default') {
    const fileName = (() => {
      switch (size) {
        // 320×180
        case 'mq':
          return 'mqdefault.jpg'
        // 480×360
        case 'hq':
          return 'hqdefault.jpg'
        // 640×480
        case 'sd':
          return 'sddefault.jpg'
        // 1280×720
        case 'max':
          return 'maxresdefault.jpg'
        // 120×90
        default:
          return 'default.jpg'
      }
    })()
    return `https://i.ytimg.com/vi/${id}/${fileName}`
  },
  escapeBreadcrumb(obj) {
    const str = JSON.stringify(obj, null, 2)
    return str.replace(/<\/script>/gi, '<\\/script>').replace(/<!--/g, '\\u003C!--')
  },
  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  },
  escapeRegex(unsafe) {
    return unsafe.replace(/[\\*+.?{}()[\]^$|/]/g, '\\$&')
  },
  /**
   * コールバック関数がtrueになるまで待ち受けるPromiseを返却
   * @param {() => boolean} cb コールバック
   */
  waitUntil(cb) {
    return new Promise(resolve => {
      const tick = () => {
        if (cb()) {
          resolve()
        } else {
          if (process.server) {
            // SSR用対応
            setTimeout(() => tick, 1000)
          } else {
            window.requestAnimationFrame(tick)
          }
        }
      }
      tick()
    })
  },
  /**
   * コールバック関数がtrueになるまで待ち受けるPromiseを返却
   * 回数制限に達したら処理を終了する
   * @param {() => boolean} cb コールバック
   * @param {number} limitCount 回数制限
   * @returns
   */
  waitUntilWithLimit(cb, limitCount = 200) {
    return new Promise((resolve, reject) => {
      const tick = () => {
        limitCount--
        if (limitCount < 0) {
          reject()
        } else {
          if (cb()) {
            resolve()
          } else {
            if (process.server) {
              // SSR用対応
              setTimeout(() => tick, 1000)
            } else {
              window.requestAnimationFrame(tick)
            }
          }
        }
      }
      tick()
    })
  },
  /**
   * queryパラメーターのtrue/falseの文字列をシンプルにBooleanに変換するメソッド
   * 'true'|'false'でない場合はvalの値をそのまま返す
   * @param {string} val
   * @param {boolean|any}
   */
  castToBooleanQueryString(val) {
    if (val === 'true' || val === 'false') {
      return val === 'true'
    }
    return val
  },
  /**
   * queryパラメーターの数字文字列をシンプルにNumberに変換するメソッド
   * 数字文字列でない場合はvalの値をそのまま返す
   * @param {string} val
   * @param {number|string}
   */
  castToNumberQueryString(val) {
    if (/^-?\d+(\.\d+)?$/.test(val)) {
      return Number(val)
    }
    return val
  },
  /**
   * クエリオブジェクトのすべてのフィールドをキャスト
   * @param {{[key: string]: string|(string|null)[]}} query
   */
  castQueryFields(query) {
    const self = this
    /**
     * クエリのフィールドをキャスト
     * @param {string} value
     */
    const castField = value => {
      value = self.castToBooleanQueryString(value)
      if (typeof value === 'boolean') {
        return value
      }
      return self.castToNumberQueryString(value)
    }
    const newQuery = {}
    Object.entries(query).forEach(([key, value]) => {
      newQuery[key] = Array.isArray(value) ? value.map(castField) : castField(value)
    })
    return newQuery
  },
  /**
   * Objectを何かしらの理由でinline-styleにしたい場合に使用
   * ex) ieで変数を動的に使いたい場合に、ie-styleをタグに埋め込む必要があるのでObjectで渡したものをinline-styleにする
   * @param {object} val
   * @returns {string}
   */
  objectToInlineStyle(val) {
    return Object.entries(val)
      .map(([k, v]) => `${k}: ${v}`)
      .join(';')
  },
  /**
   * モーダルの下の画面がスクロールしないようにする（SP用）
   * @param {boolean} flag
   * @param {boolean} isIOSOnly // iOSにしか適用しないフラグ
   * 関数内のisIOSは環境がiOSの場合の変数
   */
  bodyScrollPrevent(flag, isIOSOnly = false) {
    const ua = window.navigator.userAgent.toLowerCase()
    const isIOS =
      ua.indexOf('iphone') > -1 ||
      ua.indexOf('ipad') > -1 ||
      (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
    if (isIOSOnly && !isIOS) return

    let scrollPosition
    const body = document.body
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (flag) {
      body.style.paddingRight = scrollBarWidth + 'px'
      if (isIOS) {
        scrollPosition = -window.pageYOffset
        body.style.position = 'fixed'
        body.style.width = '100%'
        body.style.top = scrollPosition + 'px'
      } else {
        body.style.overflow = 'hidden'
      }
    } else {
      body.style.paddingRight = ''
      if (isIOS) {
        scrollPosition = parseInt(body.style.top.replace(/[^0-9]/g, ''), 10)
        body.style.position = ''
        body.style.width = ''
        body.style.top = ''
        window.scrollTo(0, scrollPosition)
      } else {
        body.style.overflow = ''
      }
    }
  },
  openZendeskWebWidget() {
    if (typeof zE === 'function') {
      zE('messenger', 'open')
    }
  },
  hideZendeskWebWidgetWhileFlag(flag) {
    if (typeof flag !== 'boolean') return
    if (typeof zE === 'function') {
      // 対象（flag）がtrueの間はチャットボタン非表示
      zE('messenger', flag ? 'hide' : 'show')
    }
  },
  /**
   * v-htmlに入れるような文字を実体参照文字に変換
   * @param {string} str
   */
  htmlspecialchars(str) {
    return (str + '')
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
  },

  autoLink(str) {
    const urlPattern =
      /(\bhttps:\/\/(?:[A-Z0-9_-]+\.)?coconala\.com(?:[/?#][A-Z0-9+&@#/%?=~_-|!:,.;]*)?)/gi
    return str.replace(urlPattern, match => {
      // ブラウザのエディタによって(Edege等)半角スペースがリンクに混ぜ込む事があるので削除する
      const link = match.trim().replace(/&nbsp;/, '')
      return `<a target="_blank" rel="noopener" href="${link}">${link}</a>`
    })
  },
  /**
   * 配列のオブジェクト重複削除
   * プロパティの並び順が異なる場合でも重複としてみなされ削除されます。
   * ex. { id: 123, name: 'hoge' } と { name: 'hoge', id: 123 }
   *
   * ※ オブジェクトinオブジェクトのプロパティの並び順が違うと重複としてみなさず、削除されません。
   * ex. {a: {b1: '', b2: ''}} と {a: {b2: '', b1: ''}} は等しくならない
   * @param {Array} array
   */
  deleteDuplicateObj(array) {
    const sortedPropertyNameObjList = array.map(v => {
      // プロパティ名をソートし格納
      const obj = Object.fromEntries(Object.entries(v).sort())
      return JSON.stringify(obj)
    })
    const unParseUniqueObjList = [...new Set(sortedPropertyNameObjList)]
    const uniqueObjList = unParseUniqueObjList.map(JSON.parse)
    return uniqueObjList
  },
  /*
   * 現在ページのy座標を取得する
   */
  getScrollY() {
    if (process.server) {
      return 0
    }
    // IE11ではwindow.scrollYがundefinedになる
    return window.scrollY || window.pageYOffset
  },
  genAppStoreUrl(isIOS) {
    return isIOS ? APP_STORE_URL.APPLE_STORE_URL : APP_STORE_URL.PLAY_STORE_URL
  },
  genAppUrl(appStoreUrl, isPC, isIOS) {
    if (isPC) {
      return this.genAppStoreUrl(isIOS)
    }

    if (appStoreUrl) {
      // SP&TBの広告流入時はOneLinkのURLにする
      return appStoreUrl
    }

    return this.genAppStoreUrl(isIOS)
  },
  genAppsFlyerKeyCookieVal(query) {
    // a8だったら早期return
    if (query.a8) return 'a8'

    switch (query.utm_source) {
      // iinaffi or criteoの場合早期return
      case 'iinaffi':
        return 'iinaffi'
      case 'criteo':
        return 'criteo'
      case 'google':
      case 'yahoo': {
        // utm_campaignはstringの時にのみパターンマッチさせる
        const matchStrArray =
          typeof query.utm_campaign === 'string'
            ? query.utm_campaign.match(/^buyer_(\w+)_text_other$/)
            : ''
        // a8 or iinaffi or criteo以外の全てのパターンが入ってくる
        return matchStrArray ? query.utm_source + '_' + matchStrArray[1] : ''
      }
      default:
        return ''
    }
  },
  /**
   * オブジェクトまたは配列の中身を見て、無効な値（null、空文字、配列の空要素）の要素を除外して返却する
   * 引数が空オブジェクト、空配列の時はnullを返す
   * Object, Array以外なら値をそのまま返す
   * @param target
   */
  omitFalsyAndBlankArray(target) {
    const propertyFilter = value => {
      if (Array.isArray(value)) {
        return !!value.length
      }
      if (value && typeof value === 'object') {
        return !!Object.keys(value).length
      }
      return typeof value === 'number' || !!value
    }
    return this.omitProperty(target, propertyFilter)
  },
  /**
   * オブジェクトまたは配列をフィルタリングして返却する
   * フィルタリングの条件は第2引数に関数で渡して使用する
   * @param target
   * @param propertyFilter
   * @return {{[p: string]: *}|null|*}
   */
  omitProperty(target, propertyFilter) {
    if (Array.isArray(target)) {
      if (!propertyFilter(target)) {
        return null
      }
      return target.map(value => this.omitProperty(value, propertyFilter)).filter(propertyFilter)
    }
    if (target && typeof target === 'object') {
      return Object.fromEntries(
        Object.entries(target)
          .map(([key, value]) => [key, this.omitProperty(value, propertyFilter)])
          .filter(([_, value]) => propertyFilter(value))
      )
    }
    // Object, Array以外ならそのまま返す
    return target
  },
  excludeInvalidAffiliateValue(affiliate_value) {
    // 文字列かつ非数だったらそのまま返却
    if (typeof affiliate_value === 'string' && isNaN(affiliate_value)) return affiliate_value
    // 配列で要素が1つなら再帰的にチェック
    if (Array.isArray(affiliate_value) && affiliate_value.length === 1) {
      return this.excludeInvalidAffiliateValue(affiliate_value[0])
    }
    // それ以外は全て例外として扱い, 保存しないのでundefinedを返す
    return undefined
  },
  /**
   * 引数の日付が今日のものか判定する
   * 引数でunixtimeを渡す場合、×1000した値を渡すようにする
   * @param {dayjs.ConfigType} time
   */
  isToday(time) {
    return (
      this.getDefaultTzDayjs(time).format('YYYYMMDD') ===
      this.getDefaultTzDayjs().format('YYYYMMDD')
    )
  },
  /**
   * 引数の日付が今年のものか判定する
   * 引数でunixtimeを渡す場合、×1000した値を渡すようにする
   * @param {dayjs.ConfigType} time
   */
  isThisYear(time) {
    const nowYear = this.getDefaultTzDayjs().year()
    const targetYear = this.getDefaultTzDayjs(time).year()
    return Number(nowYear) === Number(targetYear)
  },
  /**
   * 曜日番号から日本語の曜日名を取得する
   * @param {number} weekday
   * @return {string}
   */
  getJapaneseDayOfWeek(weekday) {
    const japaneseDayNames = ['日', '月', '火', '水', '木', '金', '土']
    return japaneseDayNames[weekday]
  },
  /**
   * 別窓でリンクを開く
   * iOSでポップアップブロックで開けない場合があるので、そのときは同窓で開く
   * ※やりようはあるのでどこまでやるかはPdMとの調整が必要
   * @param {string} path
   */
  openLinkInNewWindow(path) {
    if (!open(path)) {
      location.href = path
    }
  },
  /**
   * 全角を半角に変換する
   * @param {string} str
   */
  toHalfWidth(str) {
    const BETWEEN_FULL_HALF_WIDTH_DIFFERENCE = 65248

    const FULL_WIDTH_CHAR_CODE = {
      SPACE: 12288,
      PERIOD: 65306,
      A: 65281,
      Z: 65374
    }

    // eslint-disable-next-line
    return str.replace(/[Ａ-Ｚａ-ｚ０-９　．]/g, match => {
      const charCode = match.charCodeAt(0)

      if (charCode >= FULL_WIDTH_CHAR_CODE.A && charCode <= FULL_WIDTH_CHAR_CODE.Z) {
        return String.fromCharCode(charCode - BETWEEN_FULL_HALF_WIDTH_DIFFERENCE)
      }
      if (charCode === FULL_WIDTH_CHAR_CODE.SPACE) {
        return ' '
      }
      if (charCode === FULL_WIDTH_CHAR_CODE.PERIOD) {
        return '.'
      }

      return match
    })
  },
  /**
   * 半角文字を全角文字に変換する
   * @param {string} str
   */
  toFullWidth(str) {
    // 文字コードシフトで対応できるもの
    const formattedVal = str.replace(/[!-~]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xfee0))

    // 文字コードシフトでは対応できないもの
    const regexp = new RegExp(Object.keys(HALF_WIDTH_MAP).join('|'), 'g')
    /* eslint no-irregular-whitespace: 0 */
    return formattedVal.replace(regexp, m => HALF_WIDTH_MAP[m]).replace(/ /g, '　')
  }
}
